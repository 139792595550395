import React from "react";
import styled from '@emotion/styled';

import {
    white,
    blurple,
} from '@sentry/static-shared/utils/css/colors';
import { StaticImage } from "gatsby-plugin-image";
import Button from "@sentry/static-shared/components/Button";
import ChevronRight from '@sentry/static-shared/icons/icon-chevron-right.svg';


const PostSidebarSignUp = () => (
    <StyledSignUpCTA>
        <StaticImage src='../../assets/signup-cta.png' alt='Sentry Sign Up CTA' />
        <h3>Code breaks, fix it faster</h3>
        <p>Sign up for Sentry and monitor your application in minutes.</p>
        <Button to="https://sentry.io/signup" variant="silent" chevron={ChevronRight} redesign className="plausible-event-name=Blog+Click+Signup+Sidebar+CTA">Try Sentry Free</Button>
    </StyledSignUpCTA>
);

export default PostSidebarSignUp;

const StyledSignUpCTA = styled.div`
    background-color: ${blurple};
    border-radius: 0.5rem;
    color: ${white};
    max-width: 15.5rem;
    padding: 1.25rem 1.5rem;

    h3 {
        font-size: 1.25rem;
        font-weight: bold;
        line-height: 1.5rem;
        margin-top: 1rem;
    }

    p {
        line-height: 1.5rem;
    }

    a.btn-new.silent {
        height: 1rem;
        padding: 0;
    }
`;