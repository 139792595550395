import React from "react";
import styled from '@emotion/styled';

import {
    blurple,
    richBlack,
} from '@sentry/static-shared/utils/css/colors';
import Button from "@sentry/static-shared/components/Button";
import defaultImage from '../../assets/meta/default.png';
import { mqMin } from "@sentry/static-shared/utils/css";

const PostResourceCTA = ({resourceData, showButton = false, plausibleClasses, showExcerpt = true}) => {
    const { title, excerpt } = resourceData;
    let tileImage = defaultImage;
    let link = `/${resourceData?.slug}`;

    if (resourceData.metaImage) {
        tileImage = resourceData.metaImage.file.url;
    } else if (resourceData.coverImage) {
        tileImage = resourceData.coverImage.file.url;
        link = resourceData.link;
    }

    return (
        <StyledResourceCTA href={link} className={plausibleClasses || ''}>
            <img src={tileImage} />
            <h3>{title}</h3>
            {excerpt && showExcerpt && <p>{excerpt.excerpt}</p>}
            {showButton && <StyledButton to={link} variant="silent" redesign compensate="left" className={plausibleClasses || ''}>Read More</StyledButton>}
        </StyledResourceCTA>
    );
};

export default PostResourceCTA;

const StyledResourceCTA = styled.a`
  color: ${richBlack};

  &.suggestedContent {
      ${mqMin('md')} {
      max-width: calc(50% - 2rem);
  }

  ${mqMin('lg')} {
      max-width: calc(33.333% - 2rem);
  }
  }


  img {
      margin-bottom: 1rem;
      width: 100%;
  }

  h3 {
      font-size: 1.25rem;
      font-weight: bold;
      line-height: 1.875rem;
  }

  p:not(:first-child) {
      margin-bottom: 0;
  }
`;

const StyledButton = styled(Button)`
    &.btn-new.silent {
        color: ${blurple};
    }
`;