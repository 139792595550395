import React from 'react';
import styled from '@emotion/styled';

import Button from '@sentry/static-shared/components/Button';
import { mqMin } from '@sentry/static-shared/utils/css';
import syntaxLogo from '@sentry/static-shared/images/syntax-logo-inline.png';
import { ltPink, richBlack, white } from '../../utils/css/colors';
import Link from '../link';

const BlogCTABanner = ({ title, content, linkLabel, linkTo, ctaImage }) => {
  const ImageComponent = () =>
    (
      <Link to={linkTo} target={'_blank'} className="plausible-event-name=Blog+Bottom+CTA+Click">
        <StyledImage src={ctaImage?.file ? ctaImage.file.url : ctaImage} />
      </Link>
    )

  const isSyntaxBanner = title === BlogCTABanner.defaultProps.title;

  return (
    <StyledSyntaxBanner>
      <StyledBannerWrapper isSyntaxBanner={isSyntaxBanner}>
        <ImageComponent/>
        <StyledContentWrapper>
          <h3>{title}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
          <StyledButton to={linkTo} variant='silent' compensate='left' redesign className="plausible-event-name=Blog+Bottom+CTA+Click">{linkLabel}</StyledButton>
        </StyledContentWrapper>
      </StyledBannerWrapper>
    </StyledSyntaxBanner>
  );
};

const StyledSyntaxBanner = styled.div`
  background: ${richBlack};
  padding: 4.5rem;
`;

const StyledBannerWrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${props => props?.isSyntaxBanner ? '3.5rem' : '2.5rem'};
    justify-content: center;
    margin: 0 auto;
    max-width: 43rem;

    ${mqMin('md')} {
        flex-direction: row;
    }
`;

const StyledContentWrapper = styled.div`
    color: ${white};
    flex: 0 1 auto;

    h3 {
        font-size: 1.25rem;
    }

    p {
        line-height: 1.5rem;
    }
`;

const StyledButton = styled(Button)`
    &.btn-new.silent {
        color: ${ltPink};
    }
`;

const StyledImage = styled.img`
  object-fit: contain;
  width: 100%;
`;

BlogCTABanner.defaultProps = {
  title: "Listen to the Syntax Podcast",
  content: "<p>Of course we sponsor a developer podcast. Check it out on your favorite listening platform.</p>",
  linkLabel: "Listen To Syntax",
  linkTo: "https://syntax.fm/",
  ctaImage: syntaxLogo,
};

export default BlogCTABanner;
